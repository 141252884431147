define("discourse/plugins/discourse-reactions/discourse/routes/user-notifications-reactions-received", ["exports", "discourse/routes/discourse", "discourse/plugins/discourse-reactions/discourse/models/discourse-reactions-custom-reaction"], function (_exports, _discourse, _discourseReactionsCustomReaction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class UserNotificationsReactionsReceived extends _discourse.default {
    templateName = "user-activity-reactions";
    controllerName = "user-activity-reactions";
    queryParams = (() => ({
      acting_username: {
        refreshModel: true
      },
      include_likes: {
        refreshModel: true
      }
    }))();
    model(params) {
      return _discourseReactionsCustomReaction.default.findReactions("reactions-received", this.modelFor("user").get("username"), {
        actingUsername: params.acting_username,
        includeLikes: params.include_likes
      });
    }
    setupController(controller, model) {
      let loadedAll = model.length < 20;
      this.controllerFor("user-activity-reactions").setProperties({
        model,
        canLoadMore: !loadedAll,
        reactionsUrl: "reactions-received",
        username: this.modelFor("user").get("username"),
        actingUsername: controller.acting_username,
        includeLikes: controller.include_likes
      });
      this.controllerFor("application").set("showFooter", loadedAll);
    }
  }
  _exports.default = UserNotificationsReactionsReceived;
});